import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import useWindowSize from "../Logic/useWindowSize";
import { Link } from "react-router-dom";

export const News = (props) => {
  const [width, height] = useWindowSize();
  return (
    <a href={props.link} className="news-link">
      <Grid container className="news">
        {width > 900 && (
          <Grid md={2} className="news-img">
            <img src={props.img} alt="" srcset="" />
          </Grid>
        )}

        <Grid
          xs={12}
          md={2}
          className="news-date-and-category"
          style={{
            display: width < 900 ? "flex" : "",
            fontSize: width < 900 ? "10px" : "16px",
          }}
        >
          <div className="news-date">{props.date}</div>
          {width < 900 && (
            <div
              className="news-space"
              style={{ width: "16px", textAlign: "center" }}
            >
              |
            </div>
          )}
          <div className="news-category">{props.category}</div>
        </Grid>

        <Grid xs={12} md={8} className="news-text">
          <div
            className="news-title"
            style={{ fontSize: width < 900 ? 20 : 25 }}
          >
            {props.title}
          </div>
          <div
            className="news-description"
            style={{ fontSize: width < 900 ? 11 : 15 }}
          >
            {props.description}
          </div>
        </Grid>
      </Grid>
    </a>
  );
};
