import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import useWindowSize from "../Logic/useWindowSize";

export const About = () => {
  const [members, setMembers] = useState([]);
  const [width, height] = useWindowSize();

  useEffect(() => {
    fetch("https://infhardcore.com/requests/get_profile")
      .then((res) => res.json())
      .then((data) => {
        setMembers(data);
      });
  }, []);
  return (
    <>
      <div className="category-title">About</div>
      <div className="about-text">
        INF HARDCORE Recordsはハードコアを中心に制作している音楽レーベルです。
        各種イベントに合わせCDの頒布・デジタルリリースを行っています。
        <br />
        また、INF HARDCORE
        Recordsでは共にサークルを盛り上げるメンバーを随時募集しています。
        ご自身で制作された楽曲やデザインなど添え、Contactよりお問い合わせください。
      </div>
      <div className="category-title">Member</div>
      <Grid container>
        {members.map((item) => (
          <Grid
            xs={6}
            sm={6}
            md={4}
            lg={3}
            key={item.name}
            className="member-details"
          >
            <div className="member-img">
              <img src={"https://infhardcore.com/" + item.img} />
            </div>
            <div
              className="mamber-name"
              style={{ fontSize: width < 600 ? 18 : 24 }}
            >
              {item.name}
            </div>
            <div className="mamber-description">{item.introduction}</div>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
