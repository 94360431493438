import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";

export const Releases = () => {
  const [releaseList, setReleaseList] = useState([]);
  let json = [
    {
      id: "INF-0000",
      img: "/img/release/jacket/INF-0000.jpg",
      title: "XHAOSTIC HARDCORE",
    },
    {
      id: "INF-0001",
      img: "/img/release/jacket/INF-0001.jpg",
      title: "XHAOSTIC HARDCORE",
    },
    {
      id: "INF-0002",
      img: "/img/release/jacket/INF-0002.jpg",
      title: "XHAOSTIC HARDCORE2",
    },
    {
      id: "INF-0003",
      img: "/img/release/jacket/INF-0003.jpg",
      title: "XHAOSTIC HARDCORE3",
    },
    {
      id: "INF-0004",
      img: "/img/release/jacket/INF-0004.jpg",
      title: "XHAOSTIC HARDCORE4",
    },
    {
      id: "INF-0000",
      img: "/img/release/jacket/INF-0000.jpg",
      title: "XHAOSTIC HARDCORE",
    },
  ];

  useEffect(() => {
    fetch("https://infhardcore.com/requests/get_all_release")
      .then((res) => res.json())
      .then((data) => {
        setReleaseList(data);
      });
  }, []);

  return (
    <div>
      <div className="category-title">Releases</div>
      <Grid container>
        {releaseList.map((item) => (
          <Grid xs={4} sm={3} key={item.id} className="release-img-and-title">
            <Link to={"/release/" + item.id}>
              <div className="release-img">
                <img src={"https://infhardcore.com/" + item.img} alt="" />
              </div>
            </Link>
            <div className="release-title">{item.title}</div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
