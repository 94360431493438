import React, { useEffect, useState } from "react";

export const NotFound = () => {
  const [randNum, setRandNum] = useState(null);
  useEffect(() => {
    const randomFunc = (max) => {
      return Math.floor(Math.random() * max);
    };
    const num = randomFunc(99);
    setRandNum(num);
  }, []);
  return (
    <div className="not-found">
      <div className="not-found-text">のっとふぁうんど......!</div>
      {randNum == 0 ? (
        <img
          src="https://infhardcore.com/img/common/404_gabbanoko.png"
          alt="INF-Chang"
        />
      ) : (
        <img
          src="https://infhardcore.com/img/common/404_infchan.png"
          alt="GABBANOKO"
        />
      )}
    </div>
  );
};
