import React from "react";
import Grid from "@mui/material/Grid";

export const Contents = () => {
  return (
    <div>
      <div className="category-title">Contents</div>
      <div className="wall-paper-text">
        INF HARDCORE
        Recordsオリジナルキャラクター、INFちゃんこと「九條イオ」の壁紙です！
        <br />
        お好みのデバイスの壁紙に設定して、INFちゃんと一緒にハードコアを楽しんじゃおう！
      </div>
      <Grid container>
        <Grid sm={6} className="wall-paper">
          <img src="http://infhardcore.com/img/content/wallpaper/pc/PC_1920x1080.png" />
          <div className="wall-paper-title">For PC</div>
          <div className="wall-paper-item">
            <a href="http://infhardcore.com/img/content/wallpaper/pc/PC_1920x1080.png">
              1920x1080
            </a>
          </div>
          <div className="wall-paper-item">
            <a href="https://infhardcore.com/img/content/wallpaper/pc/PC_2560x1440.png">
              2560x1440
            </a>
          </div>
          <div className="wall-paper-item">
            <a href="https://infhardcore.com/img/content/wallpaper/pc/PC_3840x2160.png">
              3840x2160
            </a>
          </div>
        </Grid>
        <Grid sm={6} className="wall-paper">
          <img src="http://infhardcore.com/img/content/wallpaper/smartphone/SmartPhone_1000x2000.png" />
          <div className="wall-paper-title">For Smartphone</div>
          <div className="wall-paper-item">
            <a href="http://infhardcore.com/img/content/wallpaper/smartphone/SmartPhone_1000x2000.png">
              1000x2000
            </a>
          </div>
          <div className="wall-paper-item">
            <a href="http://infhardcore.com/img/content/wallpaper/smartphone/SmartPhone_2000x4000.png">
              2000x4000
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
