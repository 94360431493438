import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

export const Contact = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

  const navigate = useNavigate();

  const [token, setToken] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onVerify = useCallback((token) => {
    setToken(token);
  });

  useEffect(() => {}, []);

  const sendMessage = () => {
    if (name !== "" && email !== "" && subject !== "" && email !== "") {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, email, subject, message, token }),
      };

      fetch("https://infhardcore.com/requests/send_mail", requestOptions).then(
        (response) =>
          response.json().then((data) => {
            if (data.result) {
              navigate("/completed");
            } else {
            }
          })
      );
    } else {
    }
  };
  const handle = (e) => {
    switch (e.target.id) {
      case "name":
        setName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "subject":
        setSubject(e.target.value);
        break;
      case "message":
        setMessage(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <form className="form">
        <Grid container>
          <Grid xs={12} md={2} className="form-item">
            <div className="form-name">NAME</div>
          </Grid>
          <Grid xs={12} md={10} className="form-item">
            <input
              type="text"
              placeholder="名前"
              value={name}
              onChange={handle}
              id="name"
            />
          </Grid>
          <Grid xs={12} md={2} className="form-item">
            <div className="form-name">E-MAIL</div>
          </Grid>
          <Grid xs={12} md={10} className="form-item">
            <input
              type="text"
              placeholder="Eメール"
              value={email}
              onChange={handle}
              id="email"
            />
          </Grid>
          <Grid xs={12} md={2} className="form-item">
            <div className="form-name">SUBJECT</div>
          </Grid>
          <Grid xs={12} md={10} className="form-item">
            <input
              type="text"
              placeholder="件名"
              value={subject}
              onChange={handle}
              id="subject"
            />
          </Grid>
          <Grid xs={12} md={2} className="form-item">
            <div className="form-name">MESSAGE</div>
          </Grid>
          <Grid xs={12} md={10} className="form-item">
            <textarea
              placeholder="問い合わせ内容"
              value={message}
              onChange={handle}
              id="message"
            />
          </Grid>
          <Grid xs={12} md={2} className="form-item">
            <div className="form-name"></div>
          </Grid>
          <Grid xs={12} md={10} className="form-item">
            <button type="button" onClick={sendMessage}>
              送信
            </button>
          </Grid>
          <Grid>
            <div>
              <GoogleReCaptcha onVerify={onVerify} />
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
