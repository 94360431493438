import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import Grid from "@mui/material/Grid";
import "swiper/css";
import "swiper/css/pagination";
import { News } from "../Components/News";
import { Link } from "react-router-dom";

export const Home = () => {
  const [news, setNews] = useState([]);
  const [slide, setSlide] = useState([]);
  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ num: 6 }),
    };
    fetch("https://infhardcore.com/requests/get_news", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setNews(data);
      });

    fetch("https://infhardcore.com/requests/get_slide", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setSlide(data);
      });
  }, []);

  return (
    <div>
      <Grid>
        <Grid xs={12}>
          <div className="category-title">Infomation</div>
          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Navigation, Pagination, Autoplay]}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
            }}
            speed={500}
            loop={true}
            className="mySwiper"
          >
            {slide.map((item, index) => (
              <SwiperSlide key={index}>
                <a href={item.link}>
                  <img src={"https://infhardcore.com/" + item.img} />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
        <div className="category-title">News</div>
        {news.map((item) => (
          <News
            img={"https://infhardcore.com/" + item.img}
            category={item.category}
            title={item.title}
            description={item.description}
            date={item.date}
            link={item.link}
          />
        ))}
      </Grid>
    </div>
  );
};
