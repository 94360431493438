import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";

export const ReleaseDetail = () => {
  const params = useParams();
  // params.id を使ってフェッチする。
  const [detailJson, setDetailJson] = useState({
    musiclist: [],
    credit: [],
    link: [{ link: "" }, { link: "" }, { link: "" }],
  });
  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: params.id }),
    };

    fetch("https://infhardcore.com/requests/get_release_detail", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setDetailJson(data);
      });
  }, []);

  const json = {
    cdtitle: "XHAOSTIC HARDCORE 4",
    id: "INF-0004",
    img: "/img/release/jacket/INF-0004.jpg",
    release: "2022.10.30",
    description:
      "蹂躙せよ、カオス。2022-秋M3新作はXHAOSTIC HARDCOREシリーズVol.4！UKHC、Oldschool、ハピコア、スピードコア、フレンチコアと相も変わらずカオスな本作を是非お楽しみください！",
    musiclist: [
      { title: "Coin Operated Hero", composer: "darekasan_net" },
      { title: "Brake Done", composer: "JAMONY" },
      { title: "HolyMoly!!", composer: "Bright Freaks" },
      { title: "TOY TOXIC", composer: "HARXDistortion" },
      { title: "ClapYourHands", composer: "Yukkedon" },
      { title: "BASS MUSIC", composer: "HARXDistortion" },
      { title: "Speed, Rave, Speeeeeeeed", composer: "Zxty Vs Hiy" },
      { title: "Bloody Rose", composer: "Distorted Genuine" },
    ],
    credit: [
      { role: "DESIGN", artist: "HARXDistortion" },
      { role: "MASTERING", artist: "Hiy" },
    ],
  };

  return (
    <div>
      <div className="category-title">Releases</div>
      <Grid container>
        <Grid xs={12} md={6} className="release-detail-img">
          <img src={"https://infhardcore.com/" + detailJson.img} />
          <div className="shop">
            {detailJson.link.map((item) => (
              <a href={item.link}>
                <div className="shop-item">
                  {item.site == "BIGUP" ? "Streaming" : item.site}
                </div>
              </a>
            ))}
            {/* <div className="shop-row">
              <div className="shop-item">
                <a href={detailJson.link[1].link}>bandcamp</a>
              </div>
              <div className="shop-item">
                <a href={detailJson.link[0].link}>BOOTH</a>
              </div>
            </div>
            <div className="shop-row">
              <div className="shop-item">
                <a href={detailJson.link[2].link}>Streaming</a>
              </div>
              <div className="shop-item-none"></div>
            </div> */}
          </div>
        </Grid>
        <Grid xs={12} md={6} className="release-detail-detail">
          <div className="detail-id-and-release">
            <div className="detail-id">{detailJson.lot}</div>
            <div className="detail-release">Release | {detailJson.release}</div>
          </div>
          <div className="detail-cd-title">{detailJson.cdtitle}</div>
          <div className="detail-description">{detailJson.description}</div>
          <div className="detail-music-list">
            {detailJson.musiclist.map((item, index) => (
              <>
                <div className="detail-number-and-title">
                  <div className="detail-number">
                    {("00" + (index + 1)).slice(-2)}
                  </div>
                  <div className="detail-title">{item.title}</div>
                  <div className="detail-composer">{item.composer}</div>
                </div>
              </>
            ))}
            <div className="detail-credit">
              <div className="detail-credit-title">Credit</div>
              {detailJson.credit.map((item) => (
                <div className="detail-role-and-artist">
                  <div className="detail-role">{item.role}</div>
                  <div className="detail-artist">{item.artist}</div>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
