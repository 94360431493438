import logo from "./logo.svg";
import "./App.css";
import useWindowSize from "./Logic/useWindowSize";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

const App = ({ children }) => {
  const [width, height] = useWindowSize();

  return (
    <div className="">
      <header className="">
        <Grid container>
          {width < 600 && <Grid xs={3}></Grid>}
          <Grid xs={6} sm={4} md={2}>
            <Link to="/">
              <img src="/img/svg/logo_w.svg" />
            </Link>
          </Grid>
          <Grid md={2} />
          <Grid container className="navbar-contents" xs={11} md={6}>
            <div className="navbar-content">
              <Link to="/about" className="navbar-content-link">
                About
              </Link>
            </div>
            <div className="navbar-content">
              <Link to="/release" className="navbar-content-link">
                Releases
              </Link>
            </div>
            <div className="navbar-content">
              <Link to="/contents" className="navbar-content-link">
                Contents
              </Link>
            </div>
            <div className="navbar-content">
              <Link to="/contact" className="navbar-content-link">
                Contact
              </Link>
            </div>
          </Grid>
        </Grid>
      </header>
      <div>{children}</div>
      <footer>(C) 2018 INF HARDCORE Records</footer>
    </div>
  );
};

export default App;
