import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home";
import { About } from "./Pages/About";
import { Releases } from "./Pages/Releases";
import { Contents } from "./Pages/Contents";
import { Contact } from "./Pages/Contact";
import { ReleaseDetail } from "./Pages/ReleaseDetail";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { ContactWrapper } from "./Pages/ContactWrapper";
import { Completed } from "./Pages/Completed";
import { NotFound } from "./Pages/NotFound";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/release" element={<Releases />} />
          <Route path="/release/:id" element={<ReleaseDetail />} />
          <Route path="/contents" element={<Contents />} />
          <Route path="/contact" element={<ContactWrapper />} />
          <Route path="/completed" element={<Completed />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </App>
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
