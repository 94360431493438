import React from "react";
import { Contact } from "./Contact";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

export const ContactWrapper = () => {
  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={
          "6LdpFgAcAAAAAJjkrX2DDUynMAh187ILc5qDq7WR" /*process.env.REACT_APP_SITE_KEY*/
        }
        language="ja"
      >
        <Contact />
      </GoogleReCaptchaProvider>
    </>
  );
};
